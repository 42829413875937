import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import './ManageDestinationsPage.scss';
import { Link, Page, popupController } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '@bit/redsky.framework.rs.label';
import Switch from '@bit/redsky.framework.rs.switch';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelButton from '../../components/labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import UploadService from '../../services/upload/upload.service';
import PackageFeatureTile from '../../components/packageFeatureTile/PackageFeatureTile';
import router from '../../utils/router';
import DestinationService from '../../services/destination/destination.service';
import LoadingPage from '../loadingPage/LoadingPage';
import DestinationPackagePopup, {
	DestinationPackagePopupProps
} from '../../popups/destinationPackagePopup/DestinationPackagePopup';
import AccommodationTile from '../../components/accommodationTile/AccommodationTile';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import ConfirmDeletePopup, { ConfirmDeletePopupProps } from '../../popups/confirmDeletePopup/ConfirmDeletePopup';
import MediaService from '../../services/media/media.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import Select, { OptionType } from '@bit/redsky.framework.rs.select';
import RegionService from '../../services/region/region.service';
import UserService from '../../services/user/user.service';
import ImageManager from '../../components/imageManager/ImageManager';
import Accordion from '@bit/redsky.framework.rs.accordion';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import ExperienceTile from '../../components/experienceTile/ExperienceTile';
import DestinationExperiencePopup, {
	DestinationExperiencePopupProps
} from '../../popups/destinationExperiencePopup/DestinationExperiencePopup';
import ManageDestinationsDetailsPopup, {
	ManageDestinationsDetailsPopupProps
} from '../../popups/manageDestinationsDetailsPopup/ManageDestinationsDetailsPopup';
import DestinationCategoryPopup, {
	DestinationCategoryPopupProps
} from '../../popups/destinationCategoryPopup/DestinationCategoryPopup';
import ExperienceService from '../../services/experience/experience.service';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import axios from 'axios';
import globalState, { getRecoilExternalValue } from '../../state/globalState';
import DestinationPointsOfInterestPopup, {
	DestinationPointsOfInteredtPopupProps
} from '../../popups/destinationPointsOfInterestPopip/DestinationPointsOfInterestPopup';
import { GridContextProvider, GridDropZone, GridItem, move, swap } from 'react-grid-dnd';
import PackageService from '../../services/package/package.service';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Drag from '../../components/dnd/drag';
import Drop from '../../components/dnd/drop';
import AccommodationService from '../../services/accommodation/accommodation.service';
import SubHeaderDestination from '../../components/subHeaderDestination/SubHeaderDestination';
import useWindowScrollChange from '../../customHooks/useWindowScrollChange';
import { geoCoderApiKey } from '../../utils/constant';

interface ManageDestinationsPageProps {}

const ManageDestinationsPage: React.FC<ManageDestinationsPageProps> = () => {
	const params = router.getPageUrlParams<{ destinationId: Api.Destination.Req.Details }>([
		{ key: 'di', default: 0, type: 'integer', alias: 'destinationId' }
	]);
	let user = getRecoilExternalValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const [isItem, setItem] = useState('category');
	const uploadService = serviceFactory.get<UploadService>('UploadService');
	const experienceService = serviceFactory.get<ExperienceService>('ExperienceService');
	const mediaService: MediaService = serviceFactory.get<MediaService>('MediaService');
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const regionService = serviceFactory.get<RegionService>('RegionService');
	const userService = serviceFactory.get<UserService>('UserService');
	const packageService = serviceFactory.get<PackageService>('PackageService');
	let scrollDirection = useWindowScrollChange();
	const [logo, setLogo] = useState<string>('');
	const [heroLogo, setHeroLogo] = useState<string>('');
	const [destination, setDestination] = useState<Api.Destination.Res.Details>();
	const [mediaDetails, setMediaDetails] = useState<Api.MediaDetails[]>([]);
	const [imagesToDelete, setImagesToDelete] = useState<number[]>([]);
	const [reloadPage, setReloadPage] = useState<number>(0);
	const [initialPropertyTypeValue, setInitialPropertyTypeValue] = useState<number[]>([]);
	const [propertyTypeOptions, setPropertyTypeOptions] = useState<OptionType[]>([]);
	const [campaignOptions, setCampaignOptions] = useState<OptionType[]>([
		{ value: 2, label: 'Primary' },
		{ value: 1, label: 'Promoted' },
		{ value: 0, label: 'N/A' }
	]);
	const [primaryRate, setPrimaryRate] = useState<any>([]);
	const [selectedOption, setSelectedOption] = useState('off');
	const [rateSelectionDisabled, setRateSelectionDisabled] = useState(true);

	const [regionOptions, setRegionOptions] = useState<OptionType[]>([]);
	const [userOptions, setUserOptions] = useState<OptionType[]>([]);
	const [experienceIcons, setExperienceIcons] = useState<Api.Experience.Res.Get[]>([]);
	const [isValid, setIsValid] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);
	const [isCreate, setIsCreate] = useState<boolean>(false);
	const UpArrow = '../../images/UpArrow.svg';
	const Location = '../../images/Location.svg';
	const edit = '../../images/edit.svg';
	const [destinationDetails, setDestinationDetails] = useState({
		name: '',
		address1: '',
		address2: '',
		phone: '',
		city: '',
		zip: '',
		state: '',
		country: ''
	});

	const [packagesCategory, setPackagesCategory] = useState<any>([]);
	const [accommodations, setAccommodations] = useState<any[]>([]);
	const [totalAccommodation, setTotalAccommodation] = useState<number>(0);
	const [loadingAccommodation, setLoadingAccommodation] = useState<boolean>(false);
	const [page, setPage] = useState<number>(1);
	const perPage = 20;

	const [destinationForm, setDestinationForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('description', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Description required!'),
				new RsValidator(
					RsValidatorEnum.CUSTOM,
					'Please try and keep description to under 500 characters.',
					(control) => {
						return control.value.toString().length < 500;
					}
				)
			]),
			new RsFormControl('locationDescription', '', [
				new RsValidator(
					RsValidatorEnum.CUSTOM,
					'Please try and keep description to under 500 characters.',
					(control) => {
						if (!control.value.toString()) return true;
						return control.value.toString().length < 500;
					}
				)
			]),
			// new RsFormControl('googleReviewUrl', '', []),
			// new RsFormControl('googleTotalReview', 0, []),
			// new RsFormControl('googleAllOverReview', 0, []),
			new RsFormControl('campaignType', [], []),
			new RsFormControl('promotedRate', [], []),
			new RsFormControl('regionIds', [], []),
			new RsFormControl('merchantId', '', []),
			new RsFormControl('propertyTypeIds', []),
			new RsFormControl('allowOversell', 0, [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Must choose an option', (control) => {
					return Number(control.value) === 0 || Number(control.value) === 1;
				})
			])
		])
	);

	const [pointsOfInterestList, setPointsOfInterestList] = useState({
		pointsOfInterest: [
			{
				id: 0,
				name: '',
				distance: ''
			},
			{
				id: 1,
				name: '',
				distance: ''
			},
			{
				id: 2,
				name: '',
				distance: ''
			},
			{
				id: 3,
				name: '',
				distance: ''
			},
			{
				id: 4,
				name: '',
				distance: ''
			}
		]
	});

	const [list2, setList2] = useState<any>([]);
	const [list, setList] = useState<any>([]);

	const toggleItem = (itemId: any) => {
		const updatedList = list.map((item: any) => {
			if (item.value === itemId) {
				return { ...item, selected: !item.selected };
			}
			return item;
		});
		setList(updatedList);
	};

	const toggleItem2 = (itemId: any) => {
		const updatedList: any = list2.map((item: any) => {
			if (item.value === itemId) {
				return { ...item, selected: !item.selected };
			}
			return item;
		});
		setList2(updatedList);
	};

	const moveItems = (sourceList: any, setSourceList: any, targetList: any, setTargetList: any) => {
		const selectedItems = sourceList.filter((item: any) => item.selected);
		const unselectedItems = sourceList.filter((item: any) => !item.selected);

		setSourceList(unselectedItems);

		// Preserve selected state when moving items
		const updatedSelectedItems = selectedItems.map((item: any) => ({ ...item, selected: false }));

		setTargetList((prevList: any) => [...prevList, ...updatedSelectedItems]);
	};

	const getAccommodationList = async (destinationId: number) => {
		const pageQuery: RedSky.PagePagination = { page: page, perPage: perPage };
		let response: Api.Accommodation.Res.AdminList = await accommodationService.getPaged({
			destinationId,
			pagination: pageQuery
		});
		setAccommodations([...accommodations, ...response.data]);
		setTotalAccommodation(response.total);
		setLoadingAccommodation(false);
	};

	useEffect(() => {
		if (!!destination && destination.id) {
			setLoadingAccommodation(true);
			getAccommodationList(destination.id);
		}
	}, [page]);

	useEffect(() => {
		async function getDestination(destinationId: Api.Destination.Req.Details) {
			try {
				const allPropertyTypes = await destinationService.getAllPropertyTypes();
				const allRegions = await regionService.get();
				// const allUsers = await userService.getAdminUserList();

				setPropertyTypeOptions(formatOptions(allPropertyTypes));
				setRegionOptions(
					allRegions.map((region) => {
						return { value: region.id, label: region.name };
					})
				);

				// setUserOptions(
				// 	allUsers.map((user) => {
				// 		// return { value: user.id, label: `${user.firstName} ${user.lastName} ( ${user.id})` };
				// 		return { value: user.id, label: `${user.firstName} ${user.lastName}` };
				// 	})
				// );

				if (destinationId) {
					let rateNameList: any = await destinationService.getDetailsRateList(destinationId);

					let response: Api.Destination.Res.Details = await destinationService.getDetails(destinationId);
					setDestination(response);
					getAccommodationList(response.id);

					if (rateNameList) {
						setPrimaryRate(rateNameList);
						// setList(rateNameList)

						const commonObjects = rateNameList.filter((obj1: any) => {
							if (response?.installmentPaymentRate) {
								const installmentPaymentRate = response?.installmentPaymentRate;
								if (Array.isArray(installmentPaymentRate)) {
									// Reverse the condition
									return !installmentPaymentRate.some((obj2: any) => obj2.value === obj1.value);
								}
							}
							return true; // Reverse the default return value
						});

						const notCommonObjects = rateNameList.filter((obj1: any) => {
							if (response?.installmentPaymentRate) {
								const installmentPaymentRate = response.installmentPaymentRate;
								if (Array.isArray(installmentPaymentRate)) {
									return installmentPaymentRate.some((obj2: any) => obj2.value === obj1.value);
								}
							}
							return false;
						});
						if (!response?.installmentPaymentRate) {
							setSelectedOption('off');
						} else {
							if (commonObjects && commonObjects.length === 0) {
								setSelectedOption('allRates');
							} else {
								setSelectedOption('selectedRates');
							}
						}

						// setList2(notCommonObjects);
						setList2((prevList1: any) =>
							notCommonObjects.map((item: any, index: any) => ({
								selected: false,
								label: item.label,
								value: item.value
							}))
						);

						setList((prevList1: any) =>
							commonObjects.map((item: any, index: any) => ({
								selected: false,
								label: item.label,
								value: item.value
							}))
						);
					}

					if (response.groupPackages && response?.groupPackages.length != 0) {
						setPackagesCategory(response.groupPackages);
					} else {
						setPackagesCategory([
							{
								id: 'FoodandBeverage',
								title: 'Food and Beverage',
								isOpne: false,
								description: 'Food and Beverage',
								packages: []
							},
							{
								id: 'RentalEquipment',
								title: 'Rental Equipment',
								isOpne: false,
								description: 'Rental Equipment',
								packages: []
							},
							{
								id: 'Housekeeping',
								title: 'House keeping',
								isOpne: false,
								description: 'Housekeeping',
								packages: []
							},
							{
								id: 'otherPackages',
								title: 'otherPackages',
								isOpne: true,
								description: 'other Packages',
								packages: response.packages
							}
						]);
					}

					setLogo(response.logoUrl);
					setHeroLogo(response.heroUrl);

					let propertyTypeValue = destinationForm.getCloneDeep('propertyTypeIds');
					propertyTypeValue.value = response.propertyTypes.map((propertyType) => propertyType.id);
					setInitialPropertyTypeValue(response.propertyTypes.map((propertyType) => propertyType.id));
					setDestinationForm((prev) => prev.clone().update(propertyTypeValue));

					let campaignValue = destinationForm.getCloneDeep('campaignType');
					if (response.campaignType == null) {
						campaignValue.value = 0;
					} else {
						campaignValue.value = response.campaignType;
					}
					setDestinationForm((prev) => prev.clone().update(campaignValue));

					let regionValue = destinationForm.getCloneDeep('regionIds');
					regionValue.value = response.regions.map((region) => region.id);
					setDestinationForm((prev) => prev.clone().update(regionValue));

					let promotedRate = destinationForm.getCloneDeep('promotedRate');
					promotedRate.value = response.promotedRate || '';
					setDestinationForm((prev) => prev.clone().update(promotedRate));

					let merchantIdValue = destinationForm.getCloneDeep('merchantId');
					merchantIdValue.value = response.merchantId ? response.merchantId.toString() : '';
					setDestinationForm((prev) => prev.clone().update(merchantIdValue));

					let allowOversellValue = destinationForm.getClone('allowOversell');
					allowOversellValue.value = response.allowOversell ? 1 : 0;
					setDestinationForm((prevState) => prevState.clone().update(allowOversellValue));

					let descriptionValue = destinationForm.getCloneDeep('description');
					descriptionValue.value = response.description;
					setDestinationForm((prev) => prev.clone().update(descriptionValue));

					let locationDescriptionValue = destinationForm.getCloneDeep('locationDescription');
					locationDescriptionValue.value = response.locationDescription || '';
					setDestinationForm((prev) => prev.clone().update(locationDescriptionValue));

					// let googleReviewUrlValue = destinationForm.getCloneDeep('googleReviewUrl');
					// googleReviewUrlValue.value = response.googleReviewUrl || '';
					// setDestinationForm((prev) => prev.clone().update(googleReviewUrlValue));

					// let googleTotalReviewValue = destinationForm.getCloneDeep('googleTotalReview');
					// googleTotalReviewValue.value = response.googleTotalReview || '';
					// setDestinationForm((prev) => prev.clone().update(googleTotalReviewValue));

					// let googleAllOverReviewValue = destinationForm.getCloneDeep('googleAllOverReview');
					// googleAllOverReviewValue.value = response.googleAllOverReview || '';
					// setDestinationForm((prev) => prev.clone().update(googleAllOverReviewValue));

					setDestinationDetails({
						name: response.name,
						address1: response.address1,
						address2: response.address2,
						phone: response.phone,
						city: response.city,
						state: response.state,
						country: response.country,
						zip: response.zip
					});

					if (response.pointsOfInterest) {
						const pointsOfInterest: any = [];
						for (let index = 0; index < 5; index++) {
							if (response?.pointsOfInterest[index] === null) {
								pointsOfInterest.push({
									id: index
								});
							} else {
								pointsOfInterest.push(response?.pointsOfInterest[index]);
							}
						}

						setPointsOfInterestList({
							pointsOfInterest: pointsOfInterest
						});
					} else {
						setPointsOfInterestList(pointsOfInterestList);
					}
				} else {
					setIsCreate(true);
				}
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'),
					'Server Error'
				);
			}
		}
		getDestination(params.destinationId).catch(console.error);
	}, [reloadPage]);

	useEffect(() => {
		async function getAllExperiences() {
			try {
				const experiences = await experienceService.getAllExperiences();
				setExperienceIcons(experiences);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to find experiences.'), 'Error!');
			}
		}

		getAllExperiences().catch(console.error);
	}, [destination]);

	const toggleDone = (id: any, value: any, key: any) => {
		let updatedList = pointsOfInterestList.pointsOfInterest.map((item) => {
			if (item?.id == id) {
				return { ...item, [key]: value };
			}
			return item;
		});
		setPointsOfInterestList({ pointsOfInterest: updatedList });
	};

	function formatOptions(options: Api.Destination.Res.PropertyType[]) {
		return options.map((value) => {
			return { value: value.id, label: value.name };
		});
	}

	async function uploadImage(file: ChangeEvent<HTMLInputElement>) {
		let image = file.currentTarget.files;
		if (!image) return;
		try {
			let response: any = await uploadService.uploadImage(image[0]);
			return response.data;
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function deleteMedia(ids: number[]) {
		try {
			await Promise.all(
				ids.map(async (id) => {
					await mediaService.deleteMedia({ id: id });
				})
			);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to delete image.'), 'Error!');
			return false;
		}
	}

	async function deleteExperience(id: number, media: Api.Media[]) {
		let ids = media.map((item) => {
			return item.id;
		});
		await deleteMedia(ids);
		try {
			let response = await experienceService.deleteDestinationExperience({ id });
			if (response) rsToastify.success('Experience was successfully removed.', 'Delete Successful!');
			setReloadPage(reloadPage + 1);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function updateForm(control: RsFormControl) {
		setDestinationForm(destinationForm.clone().update(control));
	}

	async function checkIsFormValid(): Promise<boolean> {
		if (!ObjectUtils.isArrayWithData(mediaDetails)) {
			rsToastify.error('New Category requires at least one image.', 'Add Image!');
		}
		let formIsValid = await destinationForm.isValid();
		setDestinationForm(destinationForm.clone());
		return formIsValid;
	}

	function checkDestinationDetailValidationFn(destinationDetails: any) {
		if (
			destinationDetails.name &&
			destinationDetails.address1 &&
			destinationDetails.phone &&
			destinationDetails.city &&
			destinationDetails.country &&
			destinationDetails.state &&
			destinationDetails.zip
		) {
			return false;
		} else {
			rsToastify.error('Destination detail required');
			return true;
		}
	}

	function checkPointsOfInterestDetailValidationFn(pointsOfInterestList: any) {
		if (pointsOfInterestList.name) {
			if (pointsOfInterestList.name && pointsOfInterestList.distance) {
				return false;
			} else {
				rsToastify.error('pointsOfInterest detail required');
				return true;
			}
		}
	}

	async function getLocationDetails(address1: any, address2: any, city: any, country: any) {
		const result = await axios.get(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${destinationDetails.address1},${destinationDetails.address2},${destinationDetails.city},${destinationDetails.country}&key=${geoCoderApiKey}`
		);
		let location = result.data.results[0]?.geometry.location;
		return { latitude: location?.lat, longitude: location?.lng };
	}

	async function save() {
		if (!destination && !isCreate) return;
		if (!(await checkIsFormValid())) {
			rsToastify.error('Missing or incorrect information in form', 'Missing Information!');
			return;
		}
		if (!mediaDetails.find((img) => img.isPrimary)) {
			rsToastify.error('Must select a primary Image', 'Select Primary!');
			return;
		}

		if (ObjectUtils.isArrayWithData(imagesToDelete)) {
			const newMedia = await Promise.all(
				imagesToDelete.map((imageId) => {
					return mediaService.deleteMedia({ id: imageId });
				})
			);
			setImagesToDelete([]);
		}
		if (checkDestinationDetailValidationFn(destinationDetails)) return;

		const checkPointsOfInterest = pointsOfInterestList.pointsOfInterest.map((items) => {
			const dataReturn = [];

			if (items.name && !items.distance) {
				dataReturn.push(false);
			} else if (items.distance && !items.name) {
				dataReturn.push(false);
			}

			return dataReturn;
		});

		const checkPointsOfInterestdata = checkPointsOfInterest.filter((items: any) => items != false);

		if (checkPointsOfInterestdata.length) {
			rsToastify.error('pointsOfInterest detail required');
			return;
		}

		const myObjStr = JSON.stringify(pointsOfInterestList.pointsOfInterest);

		const locationDetails = await getLocationDetails(
			destinationDetails.address1,
			destinationDetails.address2,
			destinationDetails.city,
			destinationDetails.country
		);
		const form: {
			propertyTypeIds: number[];
			regionIds: number[];
			merchantId: string;
			campaignType: number;
			promotedRate: string;
			locationDescription: string;
			// googleReviewUrl: string;
			// googleTotalReview: number;
			// googleAllOverReview: number;
			description: string;
			allowOversell: 0 | 1;
		} = destinationForm.toModel();

		let setCampaignDefinition: string;

		if (form.campaignType == 2) {
			setCampaignDefinition = 'Primary';
		} else if (form.campaignType == 1) {
			setCampaignDefinition = 'Promoted';
		} else {
			setCampaignDefinition = '';
		}

		let data: any = {
			// id: destination.id,
			logoUrl: logo,
			heroUrl: heroLogo,
			mediaIds: mediaDetails,
			description: form.description,
			name: destinationDetails.name,
			address1: destinationDetails.address1,
			address2: destinationDetails.address2,
			phone: destinationDetails.phone,
			city: destinationDetails.city,
			zip: destinationDetails.zip,
			state: destinationDetails.state,
			country: destinationDetails.country,
			latitude: locationDetails?.latitude,
			longitude: locationDetails?.longitude,
			campaignType: form.campaignType,
			installmentPaymentRate: JSON.stringify(list2),
			promotedRate: form.promotedRate,
			campaignDefinition: setCampaignDefinition,
			pointsOfInterest: myObjStr
		};

		if (destination && !isCreate) data.id = destination?.id;
		if (!!form.locationDescription) {
			data.locationDescription = form.locationDescription;
		}
		// if (!!form.googleReviewUrl) {
		// 	data.googleReviewUrl = form.googleReviewUrl;
		// }
		if (ObjectUtils.isArrayWithData(form.propertyTypeIds)) {
			data.propertyTypeIds = form.propertyTypeIds;
		}
		if (ObjectUtils.isArrayWithData(form.regionIds)) {
			data.regionIds = form.regionIds;
		}
		if (form.merchantId) {
			data.merchantId = parseInt(form.merchantId);
		}
		if (isCreate) data.isCustomResort = 1;
		data.allowOversell = !!form.allowOversell;

		// return
		try {
			setLoading(true);
			// popupController.open(SpinningLoaderPopup, {});
			let response = isCreate ? await destinationService.create(data) : await destinationService.update(data);
			if (response.campaignStatusValidation == null) {
				if (!isCreate) setDestination(response);
				if (response) rsToastify.success('Save was successful!', 'Success!');
				if (!ObjectUtils.areArraysEqual(initialPropertyTypeValue, form.propertyTypeIds)) {
					rsToastify.info(
						'Property type updated for destination, see accommodations to set your accommodation to reflect the appropriate property type.',
						'Property Type Changed!'
					);
				}
				setLoading(false);
				// popupController.close(SpinningLoaderPopup);
				if (isCreate)
					await router.navigate('/dashboard/destination-list/manage-destinations?di=' + response.id);
				setIsCreate(false);
				setReloadPage((prev) => prev + 1);
			} else {
				rsToastify.error(response.name + ' has campaign type on primary.', 'Primary Campaign Exist!');
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			rsToastify.error(
				WebUtils.getRsErrorMessage(e, 'Please verify your information is correct.'),
				'Invalid request!'
			);
		}
	}

	async function handleMediaData(
		mediaData: { id: number; mediaIndex?: number | null; isPrimary: 1 | 0; image: string }[],
		imageIdsToDelete: number[]
	) {
		setMediaDetails(
			mediaData.map((img) => {
				return { id: img.id, isPrimary: img.isPrimary, mediaIndex: img.mediaIndex };
			})
		);
		setImagesToDelete(imageIdsToDelete);
	}

	async function handleMediaDataReorder(
		mediaData: { id: number; mediaIndex?: number | null; isPrimary: 1 | 0; image: string }[]
	) {
		setMediaDetails(
			mediaData.map((img) => {
				return { id: img.id, isPrimary: img.isPrimary, mediaIndex: img.mediaIndex };
			})
		);
	}

	function renderDestinationExperiences() {
		if (!destination) return;
		return destination.experiences.map((experience) => {
			let imgSrc: string = '';
			if (ObjectUtils.isArrayWithData(experience.media)) {
				let primaryImage = experience.media.find((media) => media.isPrimary === 1);
				if (primaryImage) {
					imgSrc = primaryImage.urls.imageKit;
				} else {
					imgSrc = experience.media[0].urls.imageKit;
				}
			}
			return (
				<ExperienceTile
					key={experience.id}
					id={experience.id}
					experienceId={experience.experienceId}
					title={experience.title}
					icon={experience.icon}
					description={experience.description}
					imgPath={imgSrc}
					onClick={() => {
						popupController.open<DestinationExperiencePopupProps>(DestinationExperiencePopup, {
							id: experience.id,
							experiences: experienceIcons,
							destinationId: destination.id,
							experienceId: experience.experienceId,
							title: experience.title,
							description: experience.description,
							icon: experience.icon,
							isHighlighted: experience.isHighlighted,
							onSave: () => {
								setReloadPage(reloadPage + 1);
							},
							media: experience.media.map((value) => {
								return {
									id: value.id,
									description: value.description,
									url: value.urls.small,
									title: value.title,
									urls: value.urls,
									isPrimary: value.isPrimary,
									companyId: 1,
									type: value.type,
									uploaderId: value.uploaderId
								};
							})
						});
					}}
					onDelete={() => {
						popupController.open<ConfirmDeletePopupProps>(ConfirmDeletePopup, {
							onDelete: () => {
								deleteExperience(experience.id, experience.media).catch(console.error);
							}
						});
					}}
				/>
			);
		});
	}

	async function onChange(sourceId: any, sourceIndex: any, targetIndex: any, targetId: any) {
		const newData: any = [];

		if (destination) {
			if (targetId) {
				const index33 = packagesCategory.findIndex((obj: any) => obj.id === sourceId);

				const index44 = packagesCategory.findIndex((obj: any) => obj.id === targetId);

				const result = move(
					packagesCategory[index33].packages,
					packagesCategory[index44].packages,
					sourceIndex,
					targetIndex
				);

				packagesCategory[index33].packages = result[0];
				packagesCategory[index44].packages = result[1];

				return setPackagesCategory(JSON.parse(JSON.stringify(packagesCategory)));
			}

			const index33 = packagesCategory.findIndex((obj: any) => obj.id === sourceId);

			const result = swap(packagesCategory[index33].packages, sourceIndex, targetIndex);

			packagesCategory[index33].packages = result;

			setPackagesCategory(JSON.parse(JSON.stringify(packagesCategory)));
		}
	}

	function renderDestinationPackages() {
		if (!destination) return;

		return (
			<>
				<GridContextProvider onChange={onChange}>
					{packagesCategory?.map((Category: any) => {
						return (
							<Box
								style={{
									marginLeft: Category.id !== 'otherPackages' ? 40 : 0,
									marginRight: Category.id !== 'otherPackages' ? 50 : 0
								}}
							>
								{Category.id !== 'otherPackages' && (
									<>
										<hr />
										<Box
											style={{
												justifyContent: 'space-between',
												zIndex: 1,
												display: 'flex'
											}}
											onClick={() => {
												const index33 = packagesCategory.findIndex(
													(obj: any) => obj.id === Category.id
												);

												const opnepackagesCategory = [...packagesCategory];

												opnepackagesCategory[index33].isOpne = !opnepackagesCategory[index33]
													.isOpne;

												setPackagesCategory(JSON.parse(JSON.stringify(opnepackagesCategory)));
											}}
										>
											<Label m={20} variant={'h1'}>
												{Category.title}
											</Label>

											<Label m={20} variant={'h1'}>
												<span
													style={{
														fontSize: 14,
														animationName: 'spinDown',
														animationDuration: '0.4s',
														transform: Category.isOpen ? 'rotate(-180deg)' : 'none'
													}}
													className="rsIcon accordion-rs-chevron-down iconSpinDown"
												></span>
											</Label>
										</Box>
									</>
								)}
								<GridDropZone
									className="dropzone "
									id={Category.id}
									boxesPerRow={1}
									rowHeight={140}
									style={{
										height:
											Category.packages.length !== 0 && Category.isOpne
												? Category.packages.length * 140
												: 90,
										zIndex: 0
									}}
								>
									{Category.isOpne &&
										Category.packages.map((item: any) => {
											let primaryImage: any = item.media.filter(
												(item: any) => item.isPrimary === 1
											);
											if (ObjectUtils.isArrayWithData(primaryImage))
												primaryImage = primaryImage[0].urls.imageKit;
											else primaryImage = '';
											return (
												<GridItem
													draggable
													onDragStart={() => {
														setItem('item');
													}}
													onDragEnd={() => {
														setItem('category');
													}}
													key={item.id}
												>
													<PackageFeatureTile
														key={item.id}
														imgPath={primaryImage}
														code={item.code}
														title={item.title}
														description={item.description}
														onClick={(event: any) => {
															popupController.open<DestinationPackagePopupProps>(
																DestinationPackagePopup,
																{
																	package: item,
																	onSave: (
																		updatedPackage: Api.UpsellPackage.Details
																	) => {
																		setDestination((prevState) => {
																			if (!prevState) return;
																			return {
																				...prevState,
																				packages: prevState.packages.map(
																					(upsellPackage) => {
																						if (
																							upsellPackage.id !== item.id
																						)
																							return upsellPackage;
																						return updatedPackage;
																					}
																				)
																			};
																		});
																		setReloadPage((prevState) => {
																			return prevState + 1;
																		});
																	}
																}
															);
														}}
														isPackage={true}
													/>
												</GridItem>
											);
										})}
								</GridDropZone>
							</Box>
						);
					})}
				</GridContextProvider>
			</>
		);
	}

	function renderDestinationAccommodations() {
		if (!accommodations || !destination) return;
		if (totalAccommodation > 0) {
			return (
				<>
					{accommodations.map((item, index) => {
						return (
							<AccommodationTile
								key={index}
								path={`/dashboard/destination-list/manage-destinations/manage-accommodation?ai=${item.id}&di=${destination.id}`}
								accommodationId={item.id}
								title={item.name}
								description={item.longDescription}
							/>
						);
					})}

					{accommodations.length != totalAccommodation && !loadingAccommodation && (
						<Box display={'flex'}>
							<div style={{ margin: 'auto' }}>
								<LabelButton
									className={'saveButton'}
									look={isValid ? 'containedPrimary' : 'containedSecondary'}
									disabled={!isValid}
									variant={'button'}
									label={'View More'}
									onClick={() => setPage(page + 1)}
								/>
							</div>
						</Box>
					)}
				</>
			);
		}
	}

	const reorder = (list: any, startIndex: any, endIndex: any) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const handleDragEnd = async (result: any) => {
		if (!result.destination) return;

		const sourceCategoryId = result.source.droppableId;
		const destinationCategoryId = result.destination.droppableId;

		// Reordering items
		if (result.type === 'droppable-item') {
			// If drag and dropping within the same category
			if (sourceCategoryId === destinationCategoryId) {
				const updatedOrder = reorder(
					packagesCategory.find((category: any) => category.id === sourceCategoryId).packages,
					result.source.index,
					result.destination.index
				);
				const updatedCategories = packagesCategory.map((category: any) =>
					category.id !== sourceCategoryId ? category : { ...category, packages: updatedOrder }
				);

				setPackagesCategory(updatedCategories);

				try {
					let response = await destinationService.update({
						id: destination?.id || 0,
						groupPackages: JSON.stringify(updatedCategories)
					});
					if (response) {
						setDestination(response);
					}
					popupController.close(SpinningLoaderPopup);
				} catch (e) {
					rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to toggle active status'), 'Server Error');
					popupController.closeAll();
				}
			} else {
				const sourceOrder = packagesCategory.find((category: any) => category.id === sourceCategoryId).packages;
				const destinationOrder = packagesCategory.find((category: any) => category.id === destinationCategoryId)
					.packages;

				const [removed] = sourceOrder.splice(result.source.index, 1);
				destinationOrder.splice(result.destination.index, 0, removed);

				destinationOrder[removed] = sourceOrder[removed];
				delete sourceOrder[removed];

				const updatedCategories = packagesCategory.map((category: any) =>
					category.id === sourceCategoryId
						? { ...category, packages: sourceOrder }
						: category.id === destinationCategoryId
						? { ...category, packages: destinationOrder }
						: category
				);

				setPackagesCategory(updatedCategories);

				try {
					let response = await destinationService.update({
						id: destination?.id || 0,
						groupPackages: JSON.stringify(updatedCategories)
					});
					if (response) {
						setDestination(response);
					}
					popupController.close(SpinningLoaderPopup);
				} catch (e) {
					rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to toggle active status'), 'Server Error');
					popupController.closeAll();
				}
			}
		}

		// Reordering categories
		if (result.type === 'droppable-category') {
			const updatedCategories = reorder(packagesCategory, result.source.index, result.destination.index);

			setPackagesCategory(updatedCategories);

			try {
				let response = await destinationService.update({
					id: destination?.id || 0,
					groupPackages: JSON.stringify(updatedCategories)
				});
				if (response) {
					setDestination(response);
				}
				popupController.close(SpinningLoaderPopup);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to toggle active status'), 'Server Error');
				popupController.closeAll();
			}
		}
	};

	function renderDestinationPackagesCategory(Category?: any) {
		if (!destination) return;

		return (
			<DragDropContext onDragEnd={handleDragEnd}>
				<Drop id="droppable" type="droppable-category">
					{packagesCategory.map((category: any, categoryIndex: any) => {
						return (
							<Drag
								className="draggable-category"
								key={category.id}
								id={category.id}
								index={categoryIndex}
							>
								<div
									style={{
										marginLeft: category.id !== 'otherPackages' ? 40 : 0,
										marginRight: category.id !== 'otherPackages' ? 50 : 0,

										marginBottom: 25
									}}
									className="category-container"
								>
									{category.id !== 'otherPackages' && (
										<>
											<hr />
											<div
												style={{
													justifyContent: 'space-between',
													display: 'flex'
												}}
												onClick={() => {
													const index33 = packagesCategory.findIndex(
														(obj: any) => obj.id === category.id
													);

													const opnepackagesCategory = [...packagesCategory];

													opnepackagesCategory[index33].isOpne = !opnepackagesCategory[
														index33
													].isOpne;

													setPackagesCategory(
														JSON.parse(JSON.stringify(opnepackagesCategory))
													);
												}}
											>
												<Label m={20} variant={'h1'}>
													{category.title}
												</Label>

												{category.isOpne ? (
													<Label m={20}>
														<img
															className={'rsPointsOfInterestIcons'}
															src={UpArrow}
															width={16}
														/>
													</Label>
												) : (
													<Label m={20}>
														<span className="rsIcon accordion-rs-chevron-down iconSpinDown"></span>
													</Label>
												)}
											</div>
											{category.isOpne && <Box m={20}>{category.description}</Box>}
										</>
									)}

									<Drop key={category.id} id={category.id} type="droppable-item">
										{category.isOpne &&
											category.packages.map((item: any, index: any) => {
												let primaryImage: any = item.media.filter(
													(item: any) => item.isPrimary === 1
												);
												if (ObjectUtils.isArrayWithData(primaryImage))
													primaryImage = primaryImage[0].urls.imageKit.replace('/redsky', '');
												else primaryImage = '';
												return (
													<Drag
														className="draggable"
														key={item.id}
														id={item.id}
														index={index}
													>
														<PackageFeatureTile
															key={item.id}
															imgPath={primaryImage}
															code={item.code}
															title={item.title}
															description={item.description}
															onClick={(event: any) => {
																popupController.open<DestinationPackagePopupProps>(
																	DestinationPackagePopup,
																	{
																		package: item,
																		onSave: (
																			updatedPackage: Api.UpsellPackage.Details
																		) => {
																			setDestination((prevState) => {
																				if (!prevState) return;
																				return {
																					...prevState,
																					packages: prevState.packages.map(
																						(upsellPackage) => {
																							if (
																								upsellPackage.id !==
																								item.id
																							)
																								return upsellPackage;
																							return updatedPackage;
																						}
																					)
																				};
																			});
																			setReloadPage((prevState) => {
																				return prevState + 1;
																			});
																		}
																	}
																);
															}}
															isPackage={true}
														/>
													</Drag>
												);
											})}
									</Drop>
								</div>
							</Drag>
						);
					})}
				</Drop>
			</DragDropContext>
		);
	}

	// function renderPointsOfInterest() {
	// 	if (!destination) return;
	// 	if (!destination.pointsOfInterest) return

	// 		return (
	// 			<Paper borderRadius={'4px'} >
	// 				{ destination.pointsOfInterest.map((item :any) => {
	// 					if(item.name == '') return
	// 					return(
	// 					<Box  className={'rsPointsOfInterestMain'} >
	// 						<Box marginTop={item.id === 0 ? 10 : 0 } className={'rsPointsOfInterest'}>
	// 							{
	// 								item.id === 4 ?(<img className={'rsPointsOfInterestIconsAir'}  src={AirPort} width={16} />):(
	// 								<img className={'rsPointsOfInterestIcons'} src={Location}   width={14} />)
	// 							}
	// 							<Box marginBottom={item.id === 4 ? 10 :0} className={"rsPointsOfInterestContener"}>
	// 								<Label className={'rsPointsOfInterestName'} variant={'body1'}>{item.name}</Label>
	// 								<Label className={'rsPointsOfInterestDistance'}>{item.distance}</Label>
	// 							</Box>
	// 						</Box>
	// 						<Box className={'rsPointsOfInterestEditIcons'} >
	// 							{
	// 								item.id === 0 ? (
	// 									<img
	// 									 	src={edit}
	// 										width={13}
	// 										onClick={() =>
	// 											popupController.open<DestinationPointsOfInteredtPopupProps>(DestinationPointsOfInterestPopup, {
	// 												destinationId: destination?.id || 0,
	// 												PointsOfInterest:destination?.pointsOfInterest,
	// 												onSave: () => {
	// 													setReloadPage(reloadPage + 1);
	// 												},
	// 												setPointsOfInteredt:(response : Api.Destination.Res.Details)=>{
	// 													if (response) {
	// 														setDestination(response);
	// 													}
	// 												}
	// 											})
	// 										}
	// 									  />
	// 								) :<Box height={10}  width={10}></Box>
	// 							}
	// 						</Box>
	// 					</Box>)
	// 				})}
	// 			</Paper>
	// 		);
	// }

	const affirmToggleCallBack = async (type: any) => {
		popupController.open(SpinningLoaderPopup, {});
		try {
			let response = await destinationService.update({
				id: destination?.id || 0,
				isAffirmPay: type
			});
			if (response) {
				response.isAffirmPay
					? rsToastify.success('Installment payment turned on successfully!', 'Success!')
					: rsToastify.success('Installment payment turned off successfully!', 'Success!');
				setDestination(response);
			}
			popupController.close(SpinningLoaderPopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to toggle active status'), 'Server Error');
			popupController.closeAll();
		}
	};

	const handleOptionChange = async (option: any) => {
		setSelectedOption(option);

		if (option === 'off') {
			affirmToggleCallBack(0);
			setRateSelectionDisabled(false);
		} else if (option === 'allRates') {
			if (params.destinationId) {
				popupController.open(SpinningLoaderPopup, {});
				let rateNameList: any = await destinationService.getDetailsRateList(params.destinationId);
				try {
					let response = await destinationService.update({
						id: destination?.id || 0,
						isAffirmPay: 1,
						installmentPaymentRate: JSON.stringify(rateNameList)
					});
					if (response) {
						response.isAffirmPay
							? rsToastify.success(
									'Installment payments have been successfully enabled for all rates!',
									'Success!'
							  )
							: rsToastify.success('Installment payment turned off successfully!', 'Success!');
						setDestination(response);
					}
					popupController.close(SpinningLoaderPopup);
				} catch (e) {
					rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to toggle active status'), 'Server Error');
					popupController.closeAll();
				}
				setList([]);
				setList2(rateNameList);
				setRateSelectionDisabled(false);
			}
		} else {
			if (params.destinationId) {
				let rateNameList: any = await destinationService.getDetailsRateList(params.destinationId);
				if (!destination?.isAffirmPay) {
					affirmToggleCallBack(1);
				}

				setList(rateNameList);
				setList2([]);
			}
		}
	};

	function renderInstallmentToggle() {
		return (
			<div className="installment-payments">
				<div className="control-panel">
					<Label variant={'h2'} marginTop={'24px'}>
						Installment Payment
					</Label>
					<div className="option">
						<input
							type="radio"
							id="off"
							value="off"
							checked={selectedOption === 'off'}
							onChange={() => handleOptionChange('off')}
						/>
						<label htmlFor="off">Off</label>
					</div>
					<div className="option">
						<input
							type="radio"
							id="allRates"
							value="allRates"
							checked={selectedOption === 'allRates'}
							onChange={() => handleOptionChange('allRates')}
						/>
						<label htmlFor="allRates">All Rates</label>
					</div>
					<div className="option">
						<input
							type="radio"
							id="selectedRates"
							value="selectedRates"
							checked={selectedOption === 'selectedRates'}
							onChange={() => handleOptionChange('selectedRates')}
						/>
						<label htmlFor="selectedRates">Selected Rates</label>
					</div>
				</div>
			</div>
		);
	}

	const flexibleDateRateToggle = async () => {
		popupController.open(SpinningLoaderPopup, {});
		try {
			let response = await destinationService.update({
				id: destination?.id || 0,
				flexibleDateRate: destination?.flexibleDateRate ? 0 : 1
			});
			if (response) {
				response.flexibleDateRate
					? rsToastify.success('flexible date rate turned on successfully', 'Success!')
					: rsToastify.success('flexible date rate turned off successfully', 'Success!');
				setDestination(response);
			}
			popupController.close(SpinningLoaderPopup);
		} catch (error) {
			rsToastify.error(WebUtils.getRsErrorMessage(error, 'Unable to toggle active status'), 'Server Error');
			popupController.closeAll();
		}
	};

	const urgencyMessagingToggle = async () => {
		popupController.open(SpinningLoaderPopup, {});
		try {
			let response = await destinationService.update({
				id: destination?.id || 0,
				isUrgencyMessage: destination?.isUrgencyMessage ? 0 : 1
			});
			if (response) {
				response.isUrgencyMessage
					? rsToastify.success('Urgency Message turned off successfully', 'Success!')
					: rsToastify.success('Urgency Message turned on successfully', 'Success!');
				setDestination(response);
			}
			popupController.close(SpinningLoaderPopup);
		} catch (error) {
			rsToastify.error(WebUtils.getRsErrorMessage(error, 'Unable to toggle active status'), 'Server Error');
			popupController.closeAll();
		}
	};

	const renderPointsOfInterest = () => {
		const pointsOfInterestTemp: any = [];
		for (let index = 0; index < 5; index++) {
			pointsOfInterestTemp.push({
				id: index,
				name: '',
				distance: ''
			});
		}
		return pointsOfInterestTemp.map((items: any, index: number) => {
			return (
				<Box display={'flex'} key={index}>
					<Box className={'rsPointsOfInterest'}>
						{/* {item.id === 4 ? (
												<img
													className={'rsPointsOfInterestIconsAir'}
													src={AirPort}
													width={16}
												/>
											) : (
												<img
													className={'rsPointsOfInterestIcons'}
													src={Location}
													width={14}
												/>
											)} */}
						<img className={'rsPointsOfInterestIcons'} src={Location} width={14} />
					</Box>
					<>
						<input
							type="text"
							// inputProps= {{maxLength:6}}
							value={pointsOfInterestList?.pointsOfInterest[index]?.name}
							className="pointsOfInterestInput"
							onChange={(event) => {
								const { value } = event.target;

								// Check if there is leading whitespace
								if (value.trimLeft() !== value) {
									// If leading whitespace is present, trim the value
									event.target.value = value.trimLeft();
								}

								// Update state with the trimmed value
								toggleDone(index, event.target.value, 'name');
							}}
						/>
					</>
					{/* {index === 4 ? (
											<>
												<input
													type="text"
													disabled={true}
													value={pointsOfInterestList.pointsOfInterest[index].name}
													className="pointsOfInterestInput"
													onChange={({ target: { value } }) =>
														toggleDone(index, 'airpot', 'name')
													}
												/>
											</>
										) : (
											<>
												<input
													type="text"
													// inputProps= {{maxLength:6}}
													value={pointsOfInterestList.pointsOfInterest[index].name}
													className="pointsOfInterestInput"
													onChange={({ target: { value } }) =>
														toggleDone(index, value, 'name')
													}
												/>
											</>
										)} */}
					<input
						type="text"
						// inputProps= {{maxLength:6}}
						value={pointsOfInterestList?.pointsOfInterest[index]?.distance}
						className="pointsOfInterestInput"
						onChange={(event) => {
							const { value } = event.target;

							// Check if there is leading whitespace
							if (value.trimLeft() !== value) {
								// If leading whitespace is present, trim the value
								event.target.value = value.trimLeft();
							}

							// Update state with the trimmed value
							toggleDone(index, event.target.value, 'distance');
						}}
					/>
				</Box>
			);
		});
	};

	return !destination && !isCreate ? (
		<LoadingPage />
	) : loading ? (
		<LoadingPage />
	) : (
		<>
			<SubHeaderDestination
				header={destinationDetails?.name || ''}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Destination List', link: '/dashboard/destination-list' },
					{
						label: 'Manage Destination',
						link: `/dashboard/destination-list/manage-destinations${params.destinationId}`
					}
				]}
				isValid={isValid}
				save={save}
				isCreate={isCreate}
				destinationId={destination?.id}
				destinationIsActive={destination?.isActive}
				setDestination={(res) => setDestination(res)}
			/>
			<Page className={'rsManageDestinationsPage'}>
				<div className={'pageWrapper'}>
					{/* <SubHeader
						header={destinationDetails?.name || ''}
						crumbs={[
							{ label: 'Dashboard', link: '/dashboard' },
							{ label: 'Destination List', link: '/dashboard/destination-list' },
							{
								label: 'Manage Destination',
								link: `/dashboard/destination-list/manage-destinations${params.destinationId}`
							}
						]}
					/> */}
					<Box className={'sectionOne'} margin={scrollDirection === 'DOWN' ? '100px 0 50px' : '50px 0'}>
						<LabelButton
							look={'containedPrimary'}
							variant={'button'}
							label={isCreate ? 'Add Resort Details' : 'Edit Resort Details'}
							className={'add-edit-destination'}
							onClick={() =>
								popupController.open<ManageDestinationsDetailsPopupProps>(
									ManageDestinationsDetailsPopup,
									{
										destinationDetails: destinationDetails,
										setDestinationDetails: setDestinationDetails,
										isCreate: isCreate
									}
								)
							}
						/>
						<Box display={'flex'} marginBottom={'25px'}>
							<div>
								<Label variant={'h2'}>Logo</Label>
								<Paper
									className={'destinationLogoPaper'}
									padding={'10px'}
									width={'300px'}
									height={'200px'}
									borderRadius={'4px'}
									boxShadow
								>
									<div className={'logo'} style={{ backgroundImage: `url(${logo || ''})` }} />
									<div className={'uploadWrapper'}>
										<input
											id={'LogoUpload'}
											type={'file'}
											className={'uploadInput'}
											accept="image/*"
											onChange={async (event) => {
												let imageUrl: any = await uploadImage(event);
												if (!imageUrl) return;
												setLogo(imageUrl.urls.large);
											}}
										/>
										<label htmlFor={'LogoUpload'} className={'edit'}>
											<Icon className={'edit'} iconImg={'icon-edit'} size={18} cursorPointer />
										</label>
									</div>
								</Paper>
							</div>
							<Box marginLeft={'auto'}>
								<Label variant={'h2'}>Hero Image (Recommended Size: 1440px x 420px)</Label>
								<Paper
									className={'heroImagePaper'}
									padding={'10px'}
									width={'800px'}
									height={'200px'}
									borderRadius={'4px'}
									boxShadow
								>
									<div
										className={'heroImage'}
										style={{ backgroundImage: `url(${heroLogo || ''})` }}
									/>

									<div className={'uploadWrapper'}>
										<input
											id={'HeroImgUpload'}
											type={'file'}
											className={'uploadInput'}
											accept="image/*"
											onChange={async (event) => {
												let imageUrl: any = await uploadImage(event);
												if (!imageUrl) return;
												setHeroLogo(imageUrl.urls.large);
											}}
										/>
										<label htmlFor={'HeroImgUpload'} className={'edit'}>
											<Icon className={'edit'} iconImg={'icon-edit'} size={18} cursorPointer />
										</label>
									</div>
								</Paper>
							</Box>
						</Box>
						<Box>
							<Label variant={'h2'}>Destination Images</Label>
							<ImageManager
								data={destination?.media || []}
								id={'destinationImages'}
								handleMediaData={handleMediaData}
								handleMediaDataReorder={handleMediaDataReorder}
							/>
						</Box>
						<div className={'CampaignContener'}>
							<div className={'CampaignType'}>
								<Label variant={'h2'} marginTop={'24px'}>
									Campaign Type
								</Label>
								<Select
									control={destinationForm.get('campaignType')}
									options={campaignOptions}
									updateControl={updateForm}
									isMulti={false}
									isSearchable={false}
								/>
							</div>
						</div>

						<Box style={{ display: 'flex' }}>
							{isCreate ? null : <Box style={{ marginRight: 100 }}>{renderInstallmentToggle()}</Box>}

							{isCreate ? null : (
								<Box style={{ display: 'flex' }}>
									{/* <Box style={{justifyContent:"space-between",display:"flex", backgroundColor:"red"}} > */}
									<Box
										style={{
											justifyContent: 'center',
											width: 440,
											height: 245,
											overflow: 'auto',
											boxShadow: '1px 1px 4px 1px lightgray',
											borderRadius: '4px'
										}}
									>
										<h3 style={{ textAlign: 'center' }}>Unselected</h3>
										<div>
											{list.map((item: any) => (
												<div
													// key={item.id}
													style={{
														backgroundColor: item.selected ? 'gray' : 'white',
														margin: 5,
														color: item.selected ? 'white' : 'black',
														cursor: 'pointer'
													}}
													// className={item.selected ? 'selected' : 'unselected'}
													onClick={() => toggleItem(item.value)}
												>
													{item?.label}
												</div>
											))}
										</div>
									</Box>
									<Box display="grid" alignItems="center">
										{/* <button onClick={() => moveItems(list, setList, list2, setList2)}>{`->`}</button> */}
										<LabelButton
											className={'saveButton leftRight'}
											look={isValid ? 'containedPrimary' : 'containedSecondary'}
											disabled={selectedOption == 'off' || selectedOption == 'allRates'}
											variant={'button'}
											label={'>'}
											onClick={() => moveItems(list, setList, list2, setList2)}
										/>
										<LabelButton
											className={'saveButton leftRight'}
											look={isValid ? 'containedPrimary' : 'containedSecondary'}
											disabled={selectedOption == 'off' || selectedOption == 'allRates'}
											variant={'button'}
											label={'<'}
											onClick={() => moveItems(list2, setList2, list, setList)}
										/>
										{/* <button onClick={() => moveItems(list2, setList2, list, setList)}>{`<-`}</button> */}
									</Box>

									<Box
										style={{
											justifyContent: 'center',
											width: 440,
											height: 245,
											overflow: 'auto',
											boxShadow: '1px 1px 4px 1px lightgray',
											borderRadius: '4px'
										}}
									>
										<h3 style={{ textAlign: 'center' }}>Selected</h3>
										<div>
											{list2.map((item: any) => (
												<div
													// key={item?.id}
													style={{
														backgroundColor: item.selected ? 'gray' : 'white',
														margin: 5,
														color: item.selected ? 'white' : 'black'
													}}
													// className={item.selected ? 'selected' : 'unselected'}
													onClick={() => toggleItem2(item.value)}
												>
													{item?.label}
												</div>
											))}
										</div>
									</Box>
								</Box>
							)}
						</Box>

						<Label variant={'h2'} marginTop={'24px'}>
							Property Type
						</Label>
						<Select
							width={900}
							control={destinationForm.get('propertyTypeIds')}
							options={propertyTypeOptions}
							updateControl={(control: RsFormControl) => {
								const multiSelect = typeof control.value === 'number' ? [control.value] : control.value;

								setDestinationForm(destinationForm.clone().update(control));
								if (multiSelect.length <= propertyTypeOptions.length && multiSelect.length >= 1) {
									setIsValid(true);
								} else if (multiSelect.length <= 0) {
									setIsValid(false);
									rsToastify.error('Please select a property type.', 'Property Type Required!');
								} else {
									setIsValid(false);
									rsToastify.error(
										'Unable to select more than 4 property types.',
										'Unable To Select!'
									);
								}
							}}
							isMulti
							isClearable
						/>
						<Box marginBottom={24}>
							<LabelInput
								title={'Description'}
								control={destinationForm.get('description')}
								updateControl={updateForm}
								inputType={'textarea'}
							/>
						</Box>
						<Box marginBottom={24}>
							<LabelInput
								title={'Location Description'}
								control={destinationForm.get('locationDescription')}
								updateControl={updateForm}
								inputType={'textarea'}
							/>
						</Box>
						{/* <Box marginBottom={24}>
							<LabelInput
								title={'Google Review URL'}
								control={destinationForm.get('googleReviewUrl')}
								updateControl={updateForm}
								inputType={'text'}
								className={'googleReviewUrlInput'}
							/>
						</Box>
						<Box marginBottom={24} display={'flex'} gap={50}>
							<LabelInput
								title={'Google Total Review'}
								control={destinationForm.get('googleTotalReview')}
								updateControl={updateForm}
								disabled
								inputType={'number'}
								className={'googleReviewUrlInput'}
							/>
							<LabelInput
								title={'Google Average Review'}
								control={destinationForm.get('googleAllOverReview')}
								updateControl={updateForm}
								disabled
								inputType={'number'}
								className={'googleReviewUrlInput'}
							/>
						</Box> */}
						<Box marginBottom={24} display={'flex'} gap={50}>
							<Box width={'100%'}>
								<LabelInput
									title={'Merchant Id (User Id)'}
									control={destinationForm.get('merchantId')}
									updateControl={updateForm}
									inputType={'number'}
									className={'googleReviewUrlInput'}
									labelVariant={'h3'}
								/>
							</Box>
							<Box width={'100%'}>
								<Label variant={'h3'}>Flexible Date Rate</Label>
								<Switch
									className={`toggleButton ${user?.userRoleId === 1 ? '' : 'disableCss'}`}
									label={'{"Off":"On" }'}
									checked={destination?.flexibleDateRate ? true : false}
									onChange={flexibleDateRateToggle}
								/>
							</Box>
							<Box width={'100%'}>
								<Label variant={'h3'}>Urgency Messaging</Label>
								<Switch
									className={`toggleButton`}
									label={'{"Off":"On" }'}
									checked={destination?.isUrgencyMessage ? false : true}
									onChange={urgencyMessagingToggle}
								/>
							</Box>
						</Box>

						<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>
								<div className={'CampaignType'}>
									<Label variant={'h2'} marginTop={'24px'}>
										Promoted Rate
									</Label>
									<Select
										control={destinationForm.get('promotedRate')}
										options={primaryRate}
										updateControl={updateForm}
										isMulti={false}
										isSearchable={false}
									/>
								</div>
								<div>
									<Label variant={'h3'}>Regions</Label>
									<Select
										control={destinationForm.get('regionIds')}
										options={regionOptions}
										updateControl={updateForm}
										isMulti
										isSearchable
									/>
								</div>
							</div>

							{/* </Box> */}
						</Box>

						<LabelCheckbox
							value={destinationForm.get('allowOversell').value.toString()}
							text={'Allow booking to oversell'}
							isChecked={destinationForm.get('allowOversell').value === 1}
							onSelect={() => {
								let clone = destinationForm.getCloneDeep('allowOversell');
								clone.value = 1;
								updateForm(clone);
							}}
							onDeselect={() => {
								let clone = destinationForm.getCloneDeep('allowOversell');
								clone.value = 0;
								updateForm(clone);
							}}
						/>
						<Box marginTop={20}>
							<Label variant={'h3'} className="mainPointsOfInterestTitle">
								Points of Interest
							</Label>
							<Box marginBottom={30} gap={50}>
								<Box display={'flex'} ml={30} marginBottom={-10}>
									<Label className="pointsOfInterestTitle">Name</Label>
									<Label className="pointsOfInterestTitleDistance">
										Distance (ex. 12 min Walk or 4 min drive)
									</Label>
								</Box>
								{renderPointsOfInterest()}
							</Box>
						</Box>

						{/* <Box display={'flex'}>
							<div className={'saveBtnSection'}>
								<LabelButton
									className={'saveButton'}
									look={isValid ? 'containedPrimary' : 'containedSecondary'}
									disabled={!isValid}
									variant={'button'}
									label={'Save'}
									onClick={save}
								/>
								<LabelButton
									look={'containedSecondary'}
									variant={'button'}
									label={'Cancel'}
									onClick={async () => {
										await router.navigate('/dashboard/destination-list');
									}}
								/>
							</div>
							{isCreate ? null : (
								<LabelButton
									look={'containedPrimary'}
									variant={'button'}
									label={destination?.isActive ? 'Deactivate' : 'Activate'}
									onClick={async () => {
										popupController.open(SpinningLoaderPopup, {});
										try {
											let response = await destinationService.update({
												id: destination?.id || 0,
												isActive: destination?.isActive ? 0 : 1
											});
											if (response) {
												setDestination(response);
											}
											popupController.close(SpinningLoaderPopup);
										} catch (e) {
											rsToastify.error(
												WebUtils.getRsErrorMessage(e, 'Unable to toggle active status'),
												'Server Error'
											);
											popupController.closeAll();
										}
									}}
								/>
							)}
						</Box> */}
					</Box>
					<hr />
					<Box className={'sectionTwo'}>
						<Box display={'flex'}>
							<Label variant={'h1'}>Experiences</Label>
							{/* {destination?.isCustomResort ? ( */}
							<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={'Add Experience'}
								onClick={() =>
									popupController.open<DestinationExperiencePopupProps>(DestinationExperiencePopup, {
										destinationId: destination?.id || 0,
										experiences: experienceIcons,
										title: '',
										description: '',
										icon: '',
										media: [],
										isHighlighted: 0,
										onSave: () => {
											setReloadPage(reloadPage + 1);
										}
									})
								}
							/>
							{/* ) : null} */}
						</Box>
						<Accordion>
							<Box className={'listContainer'}>{renderDestinationExperiences()}</Box>
						</Accordion>
					</Box>
					<hr />
					<Box className={'sectionThree'}>
						<Box display={'flex'} style={{ justifyContent: 'space-between' }}>
							<Label variant={'h1'}>Packages</Label>

							<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={'Add Category'}
								onClick={() =>
									popupController.open<DestinationCategoryPopupProps>(DestinationCategoryPopup, {
										onSave(title: string, description: string) {
											const newdata = [...packagesCategory];

											const secondLastIndex = newdata.length - 1;

											newdata.splice(secondLastIndex, 0, {
												title: title,
												id: title.replace(/\s/g, ''),
												description: description,
												isOpne: false,
												packages: []
											});

											setPackagesCategory(newdata);
										}
									})
								}
							/>
						</Box>
						<Accordion>
							<Box className={'listContainer'}>{renderDestinationPackagesCategory()}</Box>
						</Accordion>
					</Box>
					<hr />
					<Box className={'sectionFour'}>
						<Box display={'flex'}>
							<Label variant={'h1'}>Accommodations</Label>
							{destination?.isCustomResort ? (
								<Link
									path={`/dashboard/destination-list/manage-destinations/manage-accommodation?di=${destination?.id}`}
								>
									<LabelButton
										look={'containedPrimary'}
										variant={'button'}
										label={'Add Accommodation'}
									/>
								</Link>
							) : null}
						</Box>
						<Accordion>
							<Box className={'listContainer'}>{renderDestinationAccommodations()}</Box>
						</Accordion>
					</Box>
					<hr />
					{/* <Box className={'sectionTwoPointsOfInterest'}>
						<Box display={'flex'}>
							<Label variant={'h1'}>Points of Interest</Label>
							
							{!destination?.pointsOfInterest && (<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={'Add Points of Interest'}
								onClick={() =>
									popupController.open<DestinationPointsOfInteredtPopupProps>(DestinationPointsOfInterestPopup, {
										destinationId: destination?.id || 0,
										// PointsOfInterest:destination?.pointsOfInterest,
										onSave: () => {
											setReloadPage(reloadPage + 1);
										},
										setPointsOfInteredt:(response : Api.Destination.Res.Details)=>{
											if (response) {
												setDestination(response);
											}
										}
									})
								}
							/>)}
							
							
						</Box>
						<Accordion className='AccordionContainer'>
							<Box className={'listrenderPointsOfInterestContainer'}>{renderPointsOfInterest()}</Box>
						</Accordion>
					</Box> */}
				</div>
			</Page>
		</>
	);
};

export default ManageDestinationsPage;
